<template>
  <order-table :item="orderDetail" />
</template>

<script>
import { mapActions } from "vuex";
import OrderTable from '../../WebStore/orders/OrderTable'
export default {
  name: "ClinicOrderDetail",
  data() {
    return {
      popUpDelete: false,
      orderId: "",
      orderDetail: {},
      suppliers: [],
      currency: process.env.VUE_APP_CURRENCY,
      region: process.env.REGION,
      clinicId: sessionStorage.getItem("doctorClinicSelectedClinic"),
    };
  },
  computed: {},
  methods: {
    ...mapActions("ecommerce", [
      "fetchOrderDetail",
      "updateOrderDetail",
      "orderPayment",
    ]),
    ...mapActions("storeProducts", ["fetchSuppliers"]),
    printInvoice() {
      window.print();
    },
    getStatus() {
      const item = this.orderDetail;
      if (item.paymentType === "ClinicBankAccount") {
        if (item.status === "On Hold" || item.status === "On hold")
          return "Processing";
        else if (item.status === "Failed") return "Failed";
        else if (item.status === "Payment-Failed") return "Payment-Failed";
        else if (
          item.status === "Paid" &&
          item.trackingNumbers &&
          item.trackingNumbers.length
        )
          return "Sent";
        else return "Paid";
      } else {
        if (item.trackingNumbers && item.trackingNumbers.length) return "Sent";
        else return "Paid";
      }
    },
    getTrackingNumber(supplierId) {
      const filter = this.orderDetail.trackingNumbers.filter(
        (val) => val.supplierId === supplierId
      );
      if (filter.length) return filter[0].trackingNumber;
      else return "Pending tracking information";
    },
    getCourier(supplierId) {
      const filter = this.suppliers.filter((val) => val._id === supplierId);
      if (filter.length) return filter[0].name;
      else return "Pending tracking information";
    },
    getCouriers() {
      let couriers = "";
      if (
        this.orderDetail.trackingNumbers &&
        this.orderDetail.trackingNumbers.length > 0
      ) {
        this.orderDetail.trackingNumbers.map((item) => {
          const courier = this.getCourier(item.supplierId);
          if (courier != "Pending tracking information") {
            couriers += `${courier}, `;
          }
        });
        return couriers;
      } else {
        return "Pending tracking information";
      }
    },
    getTrackingNumbers() {
      let trackingNumbers = "";
      if (this.orderDetail.trackingNumbers.length) {
        this.orderDetail.trackingNumbers.map((item) => {
          const trackingNumber = this.getTrackingNumber(item.supplierId);
          if (trackingNumber != "Pending tracking information") {
            trackingNumbers += `${trackingNumber}, `;
          }
        });
        return trackingNumbers;
      } else {
        return "Pending tracking information";
      }
    },

    getOrderDetail() {
      this.fetchSuppliers().then((res) => {
        this.suppliers = res.data.data;
      });
      this.fetchOrderDetail({ orderId: this.orderId, clinicId: this.clinicId }).then((response) => {
        this.orderDetail = response.data.data;
      });
    },
    retryOrderPayment() {
      var self = this;
      self.$vs.loading();
      this.orderPayment({ orderId: this.orderId, status: "RetryPayment" })
        .then((response) => {
          console.log("response", response);
          self.getOrderDetail();
          self.$vs.notify({
            title: response.data.title,
            text: response.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          self.$vs.loading.close();
        })
        .catch((error) => {
          self.$vs.notify({
            title: error.data.title,
            text: error.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          self.$vs.loading.close();
        });
    },
    cancelOrder() {
      var self = this;
      self.$vs.loading();
      this.updateOrderDetail({ orderId: this.orderId, status: "Cancelled" })
        .then((response) => {
          console.log("response", response);
          self.getOrderDetail();
          self.$vs.notify({
            title: response.data.title,
            text: response.data.message,
            color: "success",
            iconPack: "feather",
            icon: "icon-alert-circle",
          });
          self.$vs.loading.close();
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  components: {
    OrderTable
  },
  async created() {
  if (this.$route.query.email) {
    this.$router.push(this.$route.path)
  }
    this.orderId = this.$route.params.orderId;
    // Set timeout for clinicId since there is a delay in getting a value
    setTimeout(() => {
        this.clinicId = sessionStorage.getItem("doctorClinicSelectedClinic");
       this.getOrderDetail();
    }, 200)
     
  },
  mounted() {
    this.$emit("setAppClasses", "invoice-page");
  },
};
</script>

<style lang="scss">
@media print {
  .invoice-page {
    * {
      visibility: hidden;
    }

    #content-area {
      margin: 0 !important;
    }

    #invoice-container,
    #invoice-container * {
      visibility: visible;
    }

    #invoice-container {
      position: absolute;
      left: 0;
      top: 0;
      box-shadow: none;
    }
  }
}
</style>
