<template>
    <div>
      <ClinicOrderDetail></ClinicOrderDetail>
    </div>
</template>

<script>
    import ClinicOrderDetail from "../../../components/order-center/order/ClinicOrderDetail";
    export default {
        name: "OrderDetail.vue",
      components: {ClinicOrderDetail}
    }
</script>

<style scoped>

</style>
